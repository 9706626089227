@import '../../styles/var';
@import '../../styles/mixins';
.searchCont{
  // @include size(91%, 72px);
  height: 33px;
  border: 1px solid #ffffff;
  background-color: white;
  border-radius: 24px;
  font-size: $fz-16;
  line-height: 22px;
  font-weight: 400;
  color: #62708D;
  display: flex;
  max-width: 100%;
  min-width: 360px;
  height: 44px;
  padding: 8px 12px;
  align-items: center;
  flex-shrink: 0;
  margin-right: 10px;
  gap:13px;
  // @media (max-width: 1024px) {
  //   @include size(87%, 42px);
  // }
  @media (max-width: 580px) {
    min-width: 1px;
    // @include size(87%, 42px);
  }
  svg{
    @include size(24px);
  }
}
.searchInput{
 display: flex;
 flex: 1;
 height: 100%;
 border: none;
 background: none;
 outline: none;
}