@import "../../../styles/var";
@import "../../../styles/mixins";
.detailCont {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 31px;
}
.taskStatus,
.deleteTask {
  padding: 4px 6px;
  border-radius: 5px;
  color: $white;
  height: fit-content;
  font-family: Mardoto-Regular;
  font-size: 16px;
}
.created {
  background-color: $gray-color;
}
.done {
  background-color: $green;
}
.dispute {
  background-color: $error-color;
}
.deleted {
  background-color: $black;
}
.answeredByAccountant {
  background-color: $yellow;
}
.deleteTask {
  background-color: $error-color;
  white-space: nowrap;
  cursor: pointer;
}
.heading,
.taskTypeHeading {
  color: $black;
  font-family: Rubik-SemiBold;
  font-size: $fz-20;
  font-style: normal;
  font-weight: 600;
  line-height: 150%; /* 30px */
  letter-spacing: -0.4px;
  margin-bottom: 20px;
}
.taskTypeHeading {
  color: $primary-color;
  color: yellow;
}
.subHeading {
  color: $primary-color;
  font-family: Mardoto-Regular;
  font-size: $fz-14;
  font-style: normal;
  font-weight: 600;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.07px;
  margin-bottom: 5px;
}
.complain {
  font-size: $fz-14;
}
.detailCont {
  color: $gray-color;
  font-family: Rubik-Medium;
  font-size: $fz-16;
  font-style: normal;
  margin-right: 100px;
  font-weight: $medium;
  line-height: 150%;
  width: 390px;
  @media (max-width: 1415px) {
    width: 285px;
  }
  @media (max-width: 425px) {
    width: 100%;
    margin-right: 0px;
  }
}
.filesCont {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 31px;
}
.passport,
.application {
  margin-bottom: 24px;
}
.cardCont {
  gap: 10px;
  flex-wrap: wrap;
}
.cardsCont {
  gap: 5px 24px;
}
.cardUploadItem {
  svg {
    margin-bottom: 10px;
    cursor: pointer;
  }
  .cardLabel {
    color: $primary-color;
    font-family: Mardoto-Regular;
    font-size: $fz-14;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 157.143% */
    letter-spacing: 0.07px;
    margin-bottom: 5px;
  }
  .cardTitle {
    color: $primary-color;
    font-family: Mardoto-Regular;
    font-size: $fz-12;
    font-style: normal;
    font-weight: $regular;
    line-height: 161.104%;
  }
}

.taskType {
  background-color: #fff;
  padding: 24px;
  border-radius: 12px;
  margin-bottom: 32px;
}
