@import '../../styles/var';
@import '../../styles/mixins';

.statusCont {
  border-radius: 5px;
  background:  #97BCC7;
  padding: 12px 16px;
  font-family: Mardoto-Medium;
  font-size: $fz-14;
  font-style: normal;
  font-weight: $medium;
  line-height: 22px; /* 157.143% */
  letter-spacing: 0.07px;
  color: $white;
  cursor: pointer;
}