@import '../../styles/var';
@import '../../styles/mixins';
.cont{
  width: 50%;
}
.label{
    color: #11133E;
    font-family: Mardoto-Regular;
    font-size: $fz-16;
    font-style: normal;
    line-height: 30px;
}

// .hhhh{
//   @include size(100%, 46px);
//   max-width: 501px;
//   border-radius: 8px;
//   border: 1px solid #EAEEF4;
//   background: #F6FAFD;
// }