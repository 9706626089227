@import "../../styles/var";
@import "../../styles/mixins";

.menuLeft {
  width: 100%;
  background: rgba(17, 19, 62, 1);
  height: 100%;
  position: relative;
}

.createdAt {
  font-size: $fz-14;
}

.main {
  padding: 110px 30px 20px;
  display: flex;
  background: #eaeaee;
  // width: calc(100% - 300px);
  width: 100%;
  min-height: 100vh;

  // margin-top: 33px;
  // @media (max-width: 1200px) {
  //   padding: 112px 10px 33px;
  // }

  // @media (max-width: 1024px) {
  //   width: 100%;
  //   padding: 55px 15px;
  // }
}

.mainWithActivityCont {
  width: calc(100%);

  @media (max-width: 1200px) {
    padding: 33px 10px;
  }

  @media (max-width: 1024px) {
    width: 100%;
    padding: 55px 15px;
  }
}

.textarea {
  width: 80%;
  min-height: 31px !important;
}

.notification {
  width: 85%;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 32px;
  position: fixed;
  top: 0;
  background-color: rgb(255, 255, 255);
  z-index: 10;
  @media (max-width: 1450px) {
    padding: 12px 65px 12px 32px;
  }
  @media (max-width: 1350px) {
    padding: 12px 95px 12px 32px;
  }
  @media (max-width: 1350px) {
    padding: 12px 115px 12px 32px;
  }
  @media (max-width: 1000px) {
    padding: 12px 125px 12px 32px;
  }
}
.notificationWidth {
  width: 96.5%;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 32px;
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 10;
  // @media (max-width: 1024px) {
  //   display: none;
  // }
}
.activityCont {
  // width: 500px;
  // width: 96%;
}
.notification_activity {
  background-color: #fff;
  border-radius: 12px;
  padding: 32px 20px;
  height: fit-content;
}

.activity {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 6px;
  font-family: Mardoto-Bold;
  font-weight: 700;
  font-size: 24px;
  line-height: 30px;
  letter-spacing: 0%;
  color: #11133e;
  margin-bottom: 24px;
}

.activityTitle {
  color: #141414;
  font-family: Rubik-Medium;
  font-size: $fz-16;
  font-style: normal;
  font-weight: $bold;
  line-height: 24px;
  // margin-bottom: 25px;
}
.activityCount {
  color: #ee1d52;
  // font-family: Rubik-Medium;
  font-size: $fz-16;
  font-style: normal;
  font-weight: $regular;
  line-height: 19px;
  // margin-bottom: 25px;
}

.notificationIcon {
  @include size(25px);
  cursor: pointer;

  svg {
    @include size(100%);
  }
}

.seeMore {
  cursor: pointer;
  color: $primary-color;
  font-size: $fz-12;
  margin: auto;
  margin-top: 10px;
}

.notifications {
  max-height: 75vh;
  overflow: auto;
  display: flex;
  flex-direction: column;
  @media (max-width: 900px) {
    overflow: unset;
  }
}

.notCont {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 16px 24px;
  box-shadow: 0px 0px 0px 0px #0000000a;

  box-shadow: 0px 1px 2px 0px #0000000a;

  box-shadow: 0px 3px 3px 0px #00000008;

  box-shadow: 0px 7px 4px 0px #00000005;
}

.activityItem {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 14px;
}

.textStyle {
  font-weight: $medium;
  font-size: 12px;
  line-height: 24px;
  color: #556ff6;
}

.notificationsDiv {
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  width: 400px;
  height: 500px;
  padding: 10px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  top: 100px;
  right: 400px;
  z-index: 7779988;
  box-shadow: 0px 0px 0px 0px #0000000f;

  box-shadow: 0px 3px 6px 0px #0000000f;

  box-shadow: 0px 10px 10px 0px #0000000d;

  box-shadow: 0px 23px 14px 0px #00000008;

  box-shadow: 0px 41px 17px 0px #00000003;
}

.close {
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.closeImage {
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  width: 20px;
  height: 20px;
}

.myInfo {
  display: flex;
  gap: 20px;

  .myAvatarDefault {
    @include size(32px);
    @include imageSize(contain, center);
    background-color: #556ff6;
    border-radius: 50%;
    padding: 10px;
  }

  .myAvatar {
    @include size(32px);
    @include imageSize(cover, center);
    border-radius: 50%;
    margin-right: 5px;
  }

  .nameProfession {
    display: flex;
    flex: 1;
    font-size: $fz-14;
    font-weight: 600;

    .name {
      font-weight: $medium;
      font-size: 18px;
      line-height: 24px;
    }
  }
}

.activityArray {
  max-height: 65vh;
  overflow: auto;
  display: flex;
  flex-direction: column-reverse;

  // margin-right: 15px;
  textarea {
  }
}

::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: rgba(28, 46, 69, 0.1);
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $primary-color;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $primary-color;
}

.textareaButton {
  button {
    width: fit-content !important;
  }
}

.mainDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #eaeaee;
}

.titleDiv {
  background-color: white;
}

.title {
  font-weight: $medium;
  font-size: 24px;
  line-height: 32px;
  color: #070018;
  font-family: Rubik-Medium;
}

// .serachDiv{
//   width: 360px;
//   height: 40px;
//   border-radius: 24px;
//   background-color: #F2F6FE;
// }
.headerIiem {
  display: flex;
  align-items: center;
  border-radius: 16px;
  background: #eaeaee;
  padding: 16px 20px;
  height: 64px;
  gap: 20px;
}

.line {
  height: 100%;
  width: 1px;
  background-color: black;
}
.lenguageDiv {
  cursor: pointer;
  display: flex;
}
.comment__date {
  position: relative;
  left: 270px;
  top: 20px;
  width: 90px;
}
.comment_text {
  font-family: Mardoto-Regular;
  font-weight: 400;
  font-size: 14px;
  line-height: 16.41px;
  letter-spacing: 0%;
  color: #7e92a2;
}
