@import "../styles/var";

.dialog-content {
  width: 500px;
  background-color: #fefefe;
  // box-shadow: 0 14px 42px 0 rgba(20,20,43,.14);
  // background-color: red;
}
.dCont {
  display: flex;
  flex-direction: column;
  gap: 25px;
}
.heading {
  font-size: $fz-24 !important;
  color: $primary-color;
}
.create-btn {
  background: $button-color !important;
  border-radius: 5px !important;
  color: white !important;
  height: 48px !important;
  padding: 12px 16px !important;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 30px;
  box-shadow: 0px 0px 0px 0px #0000000a;
  box-shadow: 0px 1px 2px 0px #0000000a;
  box-shadow: 0px 3px 3px 0px #00000008;
  box-shadow: 0px 7px 4px 0px #00000005;
}

.headerTitle {
  color: #11133e;
  font-family: Mardoto-Bold;
  font-size: $fz-18;
  font-style: normal;
  font-weight: 700;
  line-height: 30px;
}
.closeButton {
  cursor: pointer;
}
.main {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 24px 32px;
}
.buttonDiv {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
  margin-top: 16px;
}
.modal-open {
  overflow: hidden !important;
  height: 100vh !important;
  width: 100vw !important;
}
