@import '../../styles/var';
@import '../../styles/mixins';

.button{
  border-radius: 8px;
  background: #556FF6;
  color: white;
  border: none;
  padding: 10px 18px;
  font-size: $fz-14;
  font-weight: $medium;
  line-height: 20px;
  display: flex;
  align-items: center;
  // @media (max-width: 425px) {
  //   width: 100%;
  // }
}