@import "./styles/var";
@import "./styles/mixins";

body {
  margin: 0;
  // font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  //   'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  //   sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-decoration: none;
  overflow: auto !important;
}

//Mardoto
@font-face {
  font-family: "Mardoto-Black";
  src: local(Mardoto-Black), url("../public/fonts/Mardoto-Black.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Mardoto-Bold";
  src: local(Mardoto-Bold), url("../public/fonts/Mardoto-Bold.ttf");
  font-weight: normal !important;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Mardoto-BoldItalic.";
  src: local(Mardoto-Boldtalic), url("../public/fonts/Mardoto-BoldItalic.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Mardoto-Light";
  src: local(Mardoto-Light), url("../public/fonts/Mardoto-Light.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Mardoto-Medium";
  src: local(Mardoto-Medium), url("../public/fonts/Mardoto-Medium.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Mardoto-Regular";
  src: local(Mardoto-Regular), url("../public/fonts/Mardoto-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Mardoto-SemiBold";
  src: local(Mardoto-SemiBold), url("../public/fonts/Mardoto-ThinItalic.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Mardoto-Thin";
  src: local(Mardoto-Thin), url("../public/fonts/Mardoto-Thin.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

//Rubik
@font-face {
  font-family: "Rubik-Black";
  src: local(Rubik-Black), url("../public/fonts/Rubik/Rubik-Black.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Rubik-Bold";
  src: local(Rubik-Bold), url("../public/fonts/Rubik/Rubik-Bold.ttf");
  font-weight: normal !important;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Rubik-ExtraBold";
  src: local(Rubik-ExtraBold), url("../public/fonts/Rubik/Rubik-ExtraBold.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Rubik-Light";
  src: local(Rubik-Light), url("../public/fonts/Rubik/Rubik-Light.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Rubik-Medium";
  src: local(Rubik-Medium), url("../public/fonts/Rubik/Rubik-Medium.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Rubik-Regular";
  src: local(Rubik-Regular), url("../public/fonts/Rubik/Rubik-Regular.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: "Rubik-SemiBold";
  src: local(Rubik-SemiBold), url("../public/fonts/Rubik/Rubik-SemiBold.ttf");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.form-control {
  @include size(100%, 46px);
  max-width: 501px;
  border-radius: 12px !important;
  border: 2px solid #97bcc7 !important;
  padding: 0px 8px;

  &:focus {
    box-shadow: none !important;
  }
}

input {
  font-size: 16px !important;
}

code {
  font-family: Mardoto-Regular;
}

.error {
  font-family: Mardoto-Regular;
  color: $error-color;
  font-size: $fz-12;
  font-weight: 700;
}

.card-error {
  bottom: -20px;
}

.error-text {
  position: absolute;
  bottom: -17px;
  font-weight: 700;
}

.withSelected {
  position: absolute;
  top: 75px;
}

.MuiOutlinedInput-notchedOutline {
  border: none !important;
}

input {
  outline: none !important;
}

input:focus {
  border-color: #556ff6 !important;
}

.MuiTabs-root {
  min-height: 34px !important;
}

.tarifModal {
  margin: 10px !important;
}

// .taskInput {
//   width: 200px;
//   background-color: green;
//   span {
//     color: #11133E !important;
//     font-family: Mardoto-Medium !important;
//     font-size: $fz-16 !important;
//     font-style: normal;
//     line-height: 30px;
//     // margin-bottom: 10px !important;
//   }
// }

.cursor-pointer {
  cursor: pointer;
}

img {
  object-fit: cover;
}

.tabs-cont {
  .MuiButtonBase-root {
    background-color: $gray-color !important;
    color: $white !important;
    font-size: $fz-16;
    min-height: 34px !important;
    padding: 7px 16px !important;
  }

  .premium {
    border-radius: 0px 5px 5px 0px !important;
  }

  .basic {
    border-radius: 5px 0px 0px 5px !important;
  }

  .basic.Mui-selected {
    background-color: #44c7e3 !important;
  }

  .premium.Mui-selected {
    background-color: #ff4572 !important;
  }

  .MuiTabs-indicator {
    background-color: transparent !important;
  }
}

// .MuiSwitch-track{
//   background-color:#006884 !important;
// }
@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

// audio{
//   margin-left: 300px;
// }

.loading-indicator:before {
  content: "";
  background: #0000008f;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1300;
}

.container {
  &::before,
  &::after {
    content: "";
    display: none !important;
  }
}

.loading-indicator:after {
  content: "";
  animation: spin 1s ease-in-out infinite;
  position: fixed;
  width: 100%;
  top: calc(50% - 50px);
  left: calc(50% - 50px);
  z-index: 1001;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 1.5rem;
  width: 50px;
  height: 50px;
  border: 3px solid rgba(255, 255, 255, 0.3);
  border-radius: 50%;
  border-top-color: #fff;
  z-index: 99999999999;
}

.toaster-cont {
  background: #02bc7d !important;
  font-size: 14px !important;

  a {
    text-decoration: none !important;
    color: #fff !important;
  }
}

// .MuiToolbar-root {
//   min-height: 0px !important;
// }

.form-check-input:checked {
  background-color: #1976d2 !important;
  // border-color: $primary-color !important;
  border: 2px solid #1976d2 !important;
  color: white !important;
}

.languageLabel.MuiAutocomplete-root {
  width: 37px;
  
}

button:hover {
  cursor: pointer;
  box-shadow: 0 8px 7px rgba(0, 0, 0, 0.1), 0 6px 5px rgba(0, 0, 0, 0.12);
  transition: all 0.5s;
}

.languageStyle {
  position: relative;

  .MuiInputBase-input {
    font-family: Mardoto-Regular !important;
    padding: 0.5px 10px !important;
    cursor: pointer !important;
  }
}

.MuiInputBase-input {
  font-family: Mardoto-Regular !important;
}

.created {
  border-color: $gray-color !important;
}

.done {
  border-color: $green !important;
}

.dispute {
  border-color: $error-color !important;
}

.deleted {
  border-color: $black !important;
}

.answeredByAccountant {
  border-color: $yellow !important;
}

.MuiList-root {
  display: flex;
  flex-direction: column;

  li {
    padding: 6px 16px;
    justify-content: flex-start;
    font-family: Mardoto-Regular;
    font-weight: $regular;
    font-size: $fz-16;
  }
}

a {
  color: $black !important;

  &:hover {
    text-decoration: none;
  }
}

.customDayPicker {
  width: 45%;

  @media (max-width: 1150px) {
    width: 100%;
  }
}

.dashboard {
  position: relative;

  .customDayPicker {
    width: 100px;
    margin-bottom: 5px;
  }

  .MuiInputBase-root {
    height: fit-content !important;
    margin-bottom: 0px !important;
  }

  .MuiFormControl-root {
    height: fit-content !important;
  }

  label {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .dashboardPicker {
    width: 100%;
    span {
      font-size: 12px;
      color: #bababa;
    }

    input {
      width: 100%;
      height: 30px;
      max-width: 501px;
      background-color: rgb(253, 253, 253);
      // border-radius: 8px;
      border: none;
      padding: 4px;
      font-size: 12px;
      font-family: "-apple-system", "BlinkMacSystemFont", '"Segoe UI"', "Roboto",
        '"Helvetica Neue"', "Arial", "sans-serif", '"Apple Color Emoji"',
        '"Segoe UI Emoji"', '"Segoe UI Symbol"';
    }
  }
}

.rdp-day_selected {
  background-color: $primary-color !important;
}

.collapse {
  padding: 0px 10px;
}

.collapse.in {
  display: block !important;
  width: 100% !important;

  label {
    width: 85% !important;
  }
}

.collapsing {
  padding: 0px 10px;
  width: 100% !important;

  label {
    width: 85% !important;
  }
}

.MuiDialog-paper {
  border-radius: 24px !important;
}

.MuiButton-containedPrimary {
  // background-color: $primary-color !important;
}

.MuiButton-outlinedPrimary {
  border: 1px solid #556ff6 !important;
  // color: $primary-color !important;
}

.MuiButtonBase-root {
  // color: $primary-color !important;
}

.tabButton {
  border-radius: 0 !important;
  box-shadow: none !important;
  border: none !important;
  padding: 8px 32px !important;
  color: $gray-color !important;
  font-weight: $semiBold !important;
  font-size: $fz-16 !important;
  text-align: center !important;
  font-family: Rubik-Medium !important;
  // font-family: Montserrat arm !important;
  font-style: normal !important;
  line-height: normal !important;

  @media (max-width: 425px) {
    font-size: $fz-12 !important;
    padding: 8px 25px !important;
  }
}

.primary {
  // border-bottom: 2px solid #1b2559 !important;
  color: #ffffff !important;
  background-color: #556ff6 !important;
  border-radius: 4px !important;
}

.menuIcon {
  color: black;
}

.switch-filter {
  .MuiButtonBase-root {
    color: #fff;
  }

  .MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
    color: $button-color !important;
  }

  .Mui-checked + .MuiSwitch-track {
    background-color: #006884 !important;
  }
}

.MuiAccordionSummary-root {
  background-color: rgba(151, 188, 199, 0.2) !important;
  box-shadow: none !important;
}

.Mui-expanded {
  margin: 0px !important;
}

.MuiAccordionDetails-root {
  background-color: rgba(151, 188, 199, 0.2) !important;
}

.MuiAccordionSummary-content {
  font-size: $fz-24;
  font-weight: $semiBold;
  color: $primary-color;
  font-family: "Rubik-SemiBold";
  margin-top: 47px;
  margin-bottom: 27px;
}

.MuiAccordionDetails-root {
  font-size: $fz-20;
  font-weight: $regular;
  color: $black;
  font-family: "Rubik-Regular";
}

.MuiAccordion-root {
  margin-bottom: 21px !important;
}

.MuiPaper-elevation {
  box-shadow: none !important;
}

.MuiPaginationItem-root {
  font-size: 14px !important;
}

.rdp-button_reset {
  font-size: 14px !important;
}

.rdp-head_cell {
  font-size: 12px !important;
}

.audio-recorder {
  background-color: transparent !important;
  box-shadow: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.transform-rotate {
}

.round {
  @include size(20px);
  font-size: $fz-14;
  background: $error-color;
  color: $white;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
}
