@import '../../styles/var';
@import '../../styles/mixins';

.dialogCont{
    font-family: Mardoto-Medium;
    padding: 20px;
    border-radius: 24px !important;
    background: #FEFEFE;
    box-shadow: 0px 14px 42px 0px rgba(20, 20, 43, 0.14);
    // width: 100%;
    // max-width: 500px;
    width: 400px;
    .heading{
        font-size: $fz-24;
        font-style: normal;
        font-weight: $bold;
        color: $primary-color;
        font-family: Mardoto-Bold !important;
    }
}
.formCont{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.inputDiv{
    display: flex;
    flex-direction: column;
    width: 100%;
}
.inputeStyle {
    @include size(100%, 46px);
    border-radius: 8px;
    border: 1px solid #eaeef4;
    padding: 10px 20px;
    background: #f6fafd;
    font-size: $fz-12;
    font-family: Rubik-Regular;
    font-weight: 400;
  }
  .in{
    width: 100%;
  }
  .saveDiv{
    margin-top: 20px;
  }