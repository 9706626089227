@import '../../styles/var';
@import '../../styles/mixins';

.menuHeader{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    padding: 28px;
    border-bottom: 1px solid white;
    margin-bottom: 100px;
    width: 100%;
}
.iconDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    width: 32px;
    height: 32px;
}
.imgDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    width: 32px;
    height: 32px;
}
.titleDiv{
    font-weight:$medium;
    font-size: 20px;
    line-height: 26px;
    color: white;  
}