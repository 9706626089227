@import '../../../../styles/var';
@import '../../../../styles/mixins';

.tarifContainer {
  gap: 20px;
  justify-content: center;
  flex-direction: column;
  margin: 30px 0px;
}

.helpStyle {
  @include size(20px);
  margin-left: 10px;
  svg {
    @include size(20px);
    cursor: pointer;

    path {
      fill: #006884;
    }
  }
}

.selectTarif {
  color: #021620;
  font-size: $fz-16;
  font-family: Mardoto-Regular;
  margin-bottom: 4px;
  margin-right: 5px;
  font-weight: $regular;
}

.buttonsCont{
  display: flex;
  align-items: end;
  @media (max-width: 1270px) {
    margin-top: 30px;
   }
   @media (max-width: 425px) {
     margin-top: 10px;
     width: 100%;
   }

   button{
     // margin: 0px 5px;
     @media (max-width: 425px) {
       width: 178px;
       margin-bottom: 20px
     }
   }
}
.profilePhotos{
  .myProfile{
    font-family: Rubik-Medium;
    font-size: $fz-42;
    font-style: normal;
    font-weight: $medium;
    line-height: normal;
    @media (max-width: 425px) {
      font-size: $fz-24
    }
  }
  .profilePhoto{
    @include size(120px);
    @include imageSize(cover, center);
    border-radius: 50%;
  }
  .name{
    display: flex;
    align-items: end;
    margin-left: 50px;
  }

}
.formsCont{
  // margin-top: 54px;
  .inputs{
    gap: 0px 35px;
    margin-top: 10px;
    label{
      width:46%;
      @media (max-width: 425px) {
        width: 100%;
      }
    }
  }
  @media (max-width: 1024px) {
    margin: auto;
  }
  @media (max-width: 425px) {
    margin-top: 16px;
  }
}
.imageDefault{
  border-radius: 50%;
  background-color: $primary-color;
  padding: 5px;
  margin-right: 8px;
  @include size(30px);
}
.withSelected{
  width: 46%;
  margin-bottom: 20px;
  height: 0;
  @media (max-width: 654px) {
    height: fit-content;
  }
  @media (max-width: 425px) {
    width: 100%;
  }
  label{
    width: 100% !important;
    margin-bottom: 5px;
  }
  .selected{
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    max-width: 501px;
    &:first-child{
      margin-top: -16px;
    }
    .title{
      color:  $gray-color;
      font-family: Mardoto-Regular;
      font-size: $fz-14;
      font-weight: $regular;
      margin-right: 8px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      display: flex;
      flex: 1
    }
    .image{
      @include size(30px);
      // padding: 5px;
      background: $primary-color;
      margin-right: 8px;
      border-radius: 50%;
      object-fit: cover;
    }
    svg{
      cursor: pointer;
    }
  }
  .selectedAddress{
    margin-top: 10px;
  }
}
.withSelectedAccountant{
  height: fit-content !important;
}