@import "../../styles/var";
@import "../../styles/mixins";

.companyItem {
  // cursor: pointer;
  display: flex;
  flex-direction: column;
  gap:20px;
  border-radius: 6px;
  border: 1px solid #f2f2f2;
  // text-align: center;
  padding: 18px;
  // align-items: center;
  background-color: #f7f8f9;
  text-decoration: none;
  display: flex;
  min-height: 215px;
  height: 100%;
  // position: relative;
  @media screen and (max-width: 480px) {
    min-height: 162px;
    height: 100%;
    padding: 16px;
  }
  // .deactivated{
  //   height: 30px;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   font-size: 14px;
  //   color: #FFFFFF;
  //   border-radius: 4px;
  //   background-color: $text-color;
  //   position: absolute;
  //   top: 5px;
  //   left: 5px;
  //   padding: 0px 5px;
  // }

  .premiumTarif{
    background: #157FC1;
  }
  
  .nameProfession {
    width: 100%;

  
    .managers {
      color: $gray-color;
      font-feature-settings: "clig" off, "liga" off;
      font-family: Mardoto-Regular;
      font-size: $fz-18;
      font-style: normal;
      font-weight: $regular;
      line-height: 20px;
      margin-top: 10px;
    }
  }
  .name {
    color: #11133E;
    font-family: Mardoto-Medium;
    font-size: $fz-24;
    line-height: 28px;
    // display: -webkit-box;
    // -webkit-line-clamp: 2;
    // -webkit-box-orient: vertical;
    // overflow: hidden;

    @media screen and (max-width: 480px) {
      color: $button-color;
      font-size: $fz-14;
      font-weight: Mardoto-Medium;
      line-height: 16px;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      margin: 8px 0;
    }
  }

  .image {
    width: 57px;
    height: 57px;
    border-radius: 8px;
    object-fit: cover;

    @media screen and (max-width: 480px) {
      width: 80px;
      height: 80px;
    }
  }

  .managerAvatar {
    @include size(36px);
    border-radius: 100%;
    object-fit: cover;
    border: 2px solid rgb(255, 255, 255);

    @media screen and (max-width: 480px){
      @include size(25px);
    }
  }

  .managersCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
    }
  }

  .oneManagerCont {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      // margin-right: 8px;
      @include size(36px);
      object-fit: cover;

      @media screen and (max-width: 480px){
        @include size(25px);
        margin-right: 10px;
      }
    }
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .managersCount {
    width: 28px;
    height: 28px;
    border-radius: 100%;
    border: 2px solid $white;
    left: 65px;
    z-index: 100;
    background-color: rgb(214, 210, 210);
    font-size: 9px;
    color: rgba(27, 43, 65, 0.69);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .managerName {
    color: $primary-color;
    text-align: center;
    font-family: Mardoto-Medium;
    font-size: $fz-18;
    font-style: normal;
    font-weight: $medium;
    line-height: 28px;
    flex: 1;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;

    @media screen and (max-width: 480px){
      font-size: $fz-14;
      font-weight: $regular;
      line-height: 20px;
      -webkit-line-clamp: 1;
    }
  }

  .imgContainer {
    width: 57px;
    height: 57px;
    border-radius: 8px;
    background: #556FF6;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 2px solid $primary-color;
  }
  .managerDefault {
    @include size(35px);
    border-radius: 50%;
    // background: $primary-color;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 8px;
    object-fit: cover;
    img {
      @include size(25px);
      margin-right: 0px;
      object-fit: contain;
    }
  }
}
.accountantWorkerImg {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  margin-right: 10px;
  object-fit: cover;
}

.accWorkerDiv {
  display: flex;
  justify-content: center;
  align-items: center;
}

.accountantWorkerImgCont {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background: rgba(0, 104, 132, 1);
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  & img {
    width: 15px;
    height: 15px;
  }
}

.workerName {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  color: $primary-color;
  text-align: center;
  font-family: Mardoto-Medium;
  font-weight: $medium;
  font-size: 18px;
  font-style: normal;
  line-height: 28px;
}

.employeeItemMenu{
  position: relative;
  display: flex;
  cursor: pointer;
}
.menu{
  display: flex;
  flex-direction: column;
  padding: 10px 16px;
  border-radius: 16px;
  border: 1px solid rgb(192, 192, 192);
  width: 115px;
  height: 90px;
  position: absolute;
  right: 30px;
  background-color: white;
}
.menuEditDiv{
  display: flex;
  gap: 12px;
  align-items: center;
  cursor: pointer;
}
.menuEditImgDiv{
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}
.menuEditImg{
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}
.editText{
  color: #092C4C;
  font-family: Rubik-Medium;
  font-size: $fz-14;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.menuDeleteDiv{
  display: flex;
  align-items: center;
  width: 100%;
  gap: 12px;
  cursor: pointer;
}
.menuDeleteImgDiv{
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}
.menuDeleteImg{
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}
.menuDeleteDiv{
  display: flex;
  gap: 12px;
}
.deleteText{
  color: #092C4C;
  font-family: Rubik-Medium;
  font-size: $fz-14;
  font-style: normal;
  font-weight: 500;
  line-height: 30px;
}
.companyHeader{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.companyHeaderItem{
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
.tarif{
  width: 70px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $fz-12;
  line-height: 14px;
  font-weight: 400;
  color: $white;
  border-radius: 4px;
  background: #15C15D;
  // position: absolute;
  // top: 5px;
  // right: 5px;
}
.companyMain{
  width: 100%;
  display: flex;
  align-items: center;
  gap:16px;
}
.companyMainItem{
  display: flex;
  flex-direction: column;
  gap:8px;
}
.companyMainItemElement{
  display: flex;
  align-items: center;
  gap:8px;
}
.companyMainImgDiv{
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}
.companyMainImg{
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}
.companyMainItemElementText{
  font-size: $fz-14;
  line-height: 20px;
  font-weight: 400;
  color: #62708D;
  font-family: Mardoto-Regular;

}
  .modalDiv{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #00000080;
    z-index: 1000;
  }

.modalDivNone{
  display: none;
}