@import '../../styles/var';
@import '../../styles/mixins';

.cont{
  // margin-bottom: 24px;
  @media (max-width: 425px) {
    width: 100%;
  }
  .hideText{
    color: $gray-color;
    cursor: pointer;
  }
  .title{
    color: #11133E;
    font-family: Mardoto-Medium;
    font-size: $fz-16;
    font-style: normal;
    line-height: 30px;
    margin-bottom: 4px;
    margin-right: 5px;
  }


  .input{
    @include size(100%, 46px);
    max-width: 501px;
    border-radius: 8px;
    border: 1px solid #EAEEF4;
    padding: 10px 20px;
    background: #F6FAFD;
    font-size: $fz-12;
    font-family: Rubik-Regular;
    font-weight: 400;

    input{
      background: #F6FAFD;
      border: none;
      border-radius: 12px;
      height: 100%;
      outline: none;
      display: flex;
      flex: 1;
      font-size: $fz-12;
      font-family: Mardoto-Regular;
      font-weight: 400;
    }
    svg{
      cursor: pointer;      
    }
  }
  .focusedInput{
    border-color: #556FF6 !important;
  }
  .addLabel{
    color: #11133E;
    font-family: Mardoto-Medium;
    font-size: $fz-16;
    font-style: normal;
    line-height: 30px;
  }
}