@import "../../../styles/var";
@import "../../../styles/mixins";

.activityItem {
  margin-bottom: 30px;
  margin-right: 10px;
  .color {
    @include size(9px);
    background: #14a9f9;
    border-radius: 50%;
  }
  .titleNameDate {
    flex: 1;
    gap: 3px;
    justify-content: space-between;
    margin-bottom: 8px;
  }
  .title,
  .name {
    color: #060518;
    font-family: Rubik-Medium;
    font-size: $fz-14;
    font-style: normal;
    font-weight: $medium;
    line-height: normal;
    letter-spacing: 0.28px;
  }
  .title {
    margin-right: 16px;
  }
  .comment_text {
    font-family: Mardoto-Regular;
    font-weight: 400;
    font-size: 14px;
    line-height: 16.41px;
    letter-spacing: 0%;
    color: #7e92a2;
  }
  .date {
    color: #3c3e56;
    font-style: normal;
    font-family: Mardoto-Light;
    font-weight: 275;
    font-size: $fz-14;
    letter-spacing: 0.28px;
  }
  .avatarCont {
    border-radius: 50%;
    margin-right: 16px;
    background: $primary-color;
    padding: 5px;
    display: flex;
    justify-content: center;
    @include size(30px);
    img {
      @include size(100%);
      @include imageSize(contain, center);
    }
  }
  .info {
    flex: 1;
  }
  .avatar {
    @include size(32px);
    @include imageSize(contain, center);
    border-radius: 50%;
    margin-right: 16px;
  }
}
.isMine {
  flex-direction: row-reverse;
  .info {
    // align-items: end;
  }
  .title {
    margin-left: 16px;
    margin-right: 0px;
    // text-align: end;
  }
}
.description {
  font-size: $fz-14;
  font-weight: 275;
  font-family: Rubik-Light;
  color: #3c3e56;
}
