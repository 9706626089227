@import '../../styles/var';
@import '../../styles/mixins';
.cont{
  display: flex;
  width: 100%;
  flex-direction: column;
  gap:12px;

}
.label{
  color: #070018;
  font-family: Mardoto-Regular;
  font-size: $fz-16;
  line-height: 30px;
}