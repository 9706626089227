@import '../../styles/var';
@import '../../styles/mixins';

.cont{
  margin-bottom: 24px;
  position: relative;
  font-family: Mardoto-Regular;

  svg{
    fill: $gray-color;
    margin-right: 3px;
   
  }
  @media (max-width: 425px) {
    width: 100%;
  }
  .title{
    color: $black;
    font-size: $fz-16;
    font-family: Mardoto-Regular;
    margin-bottom: 4px;
    margin-right: 5px;

  }
  .input{
    @include size(100%, 46px);
    max-width: 501px;
    border-radius: 12px;
    border: 2px solid #97BCC7;
    padding: 0px 8px;
  }
}