@import "../../styles/var";
@import "../../styles/mixins";

.employeeItem {
  background: var(--gray-050, #f7f8f9);
  // text-decoration: none;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 24px;
  position: relative;

  .employeeItemHeader {
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: relative;
  }
  .employeeItemProfile {
    display: flex;
    flex-direction: row;
    gap: 10px;
  }
  .employeeItemMenu {
    position: relative;
    display: flex;
    cursor: pointer;
  }
  .menu {
    display: flex;
    flex-direction: column;
    padding: 10px 16px;
    border-radius: 16px;
    border: 1px solid rgb(192, 192, 192);
    width: 115px;
    height: 90px;
    position: absolute;
    right: 30px;
    background-color: white;
    gap: 10px;
  }
  .menuEditDiv {
    display: flex;
    gap: 12px;
    align-items: center;
    cursor: pointer;
  }
  .menuEditImgDiv {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
  }
  .menuEditImg {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
  }
  .editText {
    color: #092c4c;
    font-family: Rubik-SemiBold;
    font-size: $fz-14;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
  }
  .menuDeleteDiv {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 12px;
    cursor: pointer;
  }
  .menuDeleteImgDiv {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
  }
  .menuDeleteImg {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
  }
  .menuDeleteDiv {
    display: flex;
    gap: 12px;
  }
  .deleteText {
    color: #092c4c;
    font-family: Rubik-SemiBold;
    font-size: $fz-14;
    font-style: normal;
    font-weight: 500;
    line-height: 30px;
  }
  .employeeItemImg {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
  }
  .imgContainer {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: $primary-color;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .nameDiv {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .firstName {
    color: #11133e;
    font-family: Mardoto-Medium;
    font-size: $fz-16;
    font-style: normal;
    line-height: 22px;
  }
  .lastName {
    color: #11133e;
    font-family: Mardoto-Medium;
    font-size: $fz-16;
    font-style: normal;
    line-height: 22px;
  }
  // .nameProfession {
  //   padding: 0 0 20px 0;
  //   .name {
  //     color: #071440;
  //     font-family: Mardoto-SemiBold;
  //     font-size: $fz-20;
  //     font-style: normal;
  //     font-weight: 600;
  //     line-height: 24px;
  //     margin-bottom: 8px;
  //     display: -webkit-box;
  //     -webkit-line-clamp: 2;
  //     -webkit-box-orient: vertical;
  //     overflow: hidden;
  //   }
  //   .profession {
  //     color: #676972;
  //     font-family: Mardoto-Regular;
  //     font-size: $fz-18;
  //     font-style: normal;
  //     font-weight: $regular;
  //     line-height: 18px;
  //   }
  // }
  .image {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    object-fit: cover;
    text-align: center;
    // @media (max-width: 1480px) {
    //   height: 275px;
    // }
    // @media (max-width: 425px) {
    //   height: 190px;
    // }
  }
}
.defaultImage {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  object-fit: cover;
  text-align: center;
  // @media (max-width: 1480px) {
  //   height: 275px;
  // }
  // @media (max-width: 425px) {
  //   height: 190px;
  // }
}
.infoDiv {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
}
.infoItem {
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px;
}
.infoItemImgDiv {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}
.infoItemImg {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}
.infoItemText {
  color: #62708d;
  font-family: Mardoto-Regular;
  font-size: $fz-14;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
  overflow: hidden;
  margin-right: 30px;
}
.modalDiv {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000080;
  z-index: 1000;
}
.modalDivNone {
  display: none;
}
