@import "../../styles/var";
@import "../../styles/mixins";
.dashboardCard {
  width: 100%;
  border-radius: 8px;
  border: 1px solid rgba(28, 55, 90, 0.16);
  background: #fff;
  // border-right-width: 8px;
  text-decoration: none;
  .managerName {
    color: #939fa8;
    font-family: Mardoto-Regular;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-left: 5px;
  }
  .dashboardCardTop {
    padding: 14px;
    width: 100%;
    // min-height: 74px;
    // flex-shrink: 0;
    display: flex;
    justify-content: space-between;
    gap: 5px;
    .taskType {
      color: #939fa8;
      font-family: Mardoto-Regular;
      font-size: 13px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
  .taskName {
    padding: 1px;
    color: #070018;
    // font-family: Mardoto-Regular;
    font-size: $fz-16;
    font-style: normal;
    line-height: 22px;
    font-weight: $medium;
    width: 60%;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .notificationCont {
    position: relative;
    svg {
      @include size(15px, 20px);
    }
    .round {
      @include size(8px);
      background: $error-color;
      border: 1px solid $white;
      border-radius: 50%;
      position: absolute;
      top: 5px;
      right: 0px;
    }
  }
  .dashboardCardCont {
    padding: 15px;
    justify-content: center;
    svg {
      margin-right: 5px;
    }
    .managerSrc {
      @include size(16px);
      border-radius: 50%;
      background-color: $primary-color;
      img {
        @include size(12px);
        object-fit: contain;
      }
    }
    .dashboardCardMain {
      display: flex;
      flex-direction: column;
      flex: 1;
      gap: 8px;
    }
    .companyName {
      color: $black;
      font-size: $fz-16;
      margin-bottom: 10px;
      font-family: Mardoto-Regular;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      width: calc(100% - 32px);
    }
    .createdAt {
      color: $gray-color;
      font-family: Mardoto-Regular;
      font-size: $fz-13;
      font-style: normal;
      font-weight: $regular;
      line-height: normal;
    }
  }
}

.buttonDivDispute {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  border-radius: 20px;
  padding: 5px 12px;
  background: #ee1d52;
}
// .buttonDivCreated{

// }
.dashboardCardMainItem {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2px;
}
.iconDiv {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}
.iconStyle {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}
.textStyle {
  color: #62708d;
  font-family: Mardoto-Regular;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}
.buttonTitle {
  color: white;
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
}
