@import "../../../styles/var";
@import "../../../styles/mixins";

.filterCont {
  padding: 24px 33px;
  width: calc(100% + 60px);
  margin-left: -30px;
  margin-top: 45px;
  .filterIcon {
    display: none;
    @media (max-width: 1400px) {
      display: flex;
      cursor: pointer;
    }
  }
  // .filterRightSideCont{
  //   flex-wrap: wrap;
  //   width: 100%;
  //   background-color: red;
  //   // @media (max-width: 1400px) {
  //   // }
  //   @media (max-width: 768px) {
  //     padding: 0px 15px;
  //   }
  // }
}
.filterRightSideCont {
  // flex-wrap: wrap;
  width: 100%;
  background-color: rgb(247, 246, 246);
  height: 74px;
  border-radius: 12px;
  // @media (max-width: 1400px) {
  // }
  @media (max-width: 768px) {
    padding: 0px 15px;
  }
}
.createTask {
  // @include size(36px);
  // display: flex;
  // justify-content: center;
  // align-items: center;
  // font-size: 20px !important;
  color: white;
  border-radius: 36px;
  cursor: pointer;
}
.DashboardCardCont {
  height: calc(100vh - 212px);
  margin-top: 30px;
  display: flex;
  gap: 20px;

  @media (max-width: 1500px) {
    overflow: auto;
  }
}
.DashboardCardCont100 {
  height: calc(100vh - 308px);
  margin-top: 30px;
  display: flex;
  gap: 20px;
  @media (max-width: 1500px) {
    overflow: auto;
  }

  // display: grid;
  // grid-template-columns: repeat(5,calc((100% - 50px) / 5));
  // grid-gap: 13px;
  // @media (max-width: 19.2%) {
  //   grid-template-columns: repeat(4,calc((100% - 30px) / 4));
  // }
  // @media (max-width: 19.2%) {
  //   grid-template-columns: repeat(3,calc((100% - 30px) / 3));
  // }
}

.noDataAvailableCont {
  width: 100%;
  padding: 100px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 1300px) {
    & img {
      width: 300px;
    }
  }

  @media (max-width: 380px) {
    & img {
      width: 200px;
    }
  }
}
.filterMain {
  width: 100%;
}
.filterItem {
  width: 100%;
  height: 52px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 20px;
}
.filterDiv {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  padding: 8px 16px;
  background-color: white;
}
.filterIconDiv {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}
.filterIconStyle {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}
.filterText {
  font-weight: $medium;
  font-size: 16px;
  line-height: 20px;
  color: #11133e;
}
.selectDiv {
  background-color: rgb(251, 251, 251);
  width: 19.2%;
  border-radius: 12px;
  padding: 16px;
}
.dashboardItem {
  // overflow: hidden;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  gap: 24px;
  width: 25%;
}
.dashboardItemHeader {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
.dashboardItemHeaderTitle {
  font-weight: $semiBold;
  font-size: 18px;
  line-height: 20px;
  color: #070018;
}
.dashboardItemHeaderTask {
  font-weight: $regular;
  font-size: 14px;
  line-height: 20px;
  color: #62708d;
}
.dashboardItemTask {
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-right: 10px;
}
.dayPickerStyle {
  width: 100%;
}
