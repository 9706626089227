@import '../../styles/var';
@import '../../styles/mixins';
.cont{
    display: flex;
    background-color: red;
    width: 500px;
}
.profilePhotos{
  .myProfile{
    // color: #FEFEFE;
    font-family: Rubik-Medium;
    font-size: $fz-42;
    font-style: normal;
    font-weight: $medium;
    line-height: normal;
  }
  .profilePhoto{
    @include size(120px);
    @include imageSize(cover, center);
    border-radius: 50%;
  }
  .name{
    display: flex;
    align-items: end;
    margin-left: 50px;
  }

}