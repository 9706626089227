@import "../../styles/var";
@import "../../styles/mixins";
.title {
  color: $black;
  font-size: $fz-16;
  font-family: Mardoto-Regular;
  margin-bottom: 4px;
  margin-right: 5px;
  font-weight: 700;
}
.titleDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
  // width: 200px;
}
.formGrup {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: 100%;
  gap: 3px;
  height: 100px;
  padding: 5px;
  & .MuiFormControlLabel-root {
    font-family: Mardoto-Regular;
  }
}
.formLabel {
  width: 48%;
  height: 20px;
  color: #374151;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}
// .chek{
//   width: 50px;
//   height: 50px;
// }
