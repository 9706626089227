@import '../../styles/var';
@import '../../styles/mixins';

.profilePhotoEdit{
  position: relative;
  display: flex;
  align-items: flex-end;
  width: 100px;
  height: 100px;
  // @media (max-width: 480px) {
  //     margin-bottom: 20px
  //   }
  
  .editAvatar{
    position: absolute;
    bottom: -7px;
    right: -13px;
    border-radius: 50%;
    // box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
    padding: 8px;
    z-index: 1;
    &:hover{
      cursor: pointer;
    }
    
  }
}
.myProfilePhoto{
  @include size(100px);
  @include imageSize(cover, center);
  border-radius: 50%;
  @media (max-width: 425px) {
    @include size(100px);
  }
}
.profilePhoto{
  @include size(100px);
  @include imageSize(contain, center);
  border-radius: 50%;
  padding: 30px 35px;
  background: #F6FAFD;
  // @media (max-width: 1400px) {
  //   @include size(150px);
  // }
  @media (max-width: 425px) {
    @include size(100px);
  }
}
.profilePhotoEditCont{
  @media (max-width: 425px) {
    margin: auto;
  }
}