@import '../../styles/var';
@import '../../styles/mixins';

.notificationItem{
  display: flex;
  gap:12px;
  padding: 14px 24px;
  border-bottom: 1px solid rgb(216, 216, 216);
  cursor: pointer;
  .color{
    @include size(9px);
    background:#14A9F9;
    border-radius: 50%;
  }
  .colorTransparent{
    background:transparent;
  }
  .titleNameDate{
    display: flex;
    flex-direction: column;
  }
  .title{
  color: rgba(24, 39, 58, 0.94);
  font-family: Rubik-Medium;
  font-size: $fz-12;
  font-style: normal;
  font-weight: $regular;
  line-height: 18px;
  }
  .name{
    font-size: $fz-12;
    font-weight: $medium;
    line-height: 24px;
    color: #070018;
  }
  .date{
    color: #62708D;
    font-family: Rubik-Regular;
    font-size: $fz-12;
    font-style: normal;
    font-weight: $medium;
    line-height: 24px;
  }
  .avatar{
    margin-top: 7px;
    @include size(32px);
    @include imageSize('cover', 'center');
    border-radius: 50%;
  }
  .avatarCont{
    @include size(32px);
    border-radius: 50%;
    background: $primary-color;
   
    display: flex;
    justify-content: center;
    img{
      @include size(100%);
      @include imageSize(contain, center);
    }
  }
}