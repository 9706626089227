@import '../../styles/var';
@import '../../styles/mixins';
.label{
  width: 100%;
  color: #BABABA;
  font-family: Mardoto-Regular;
  font-size: $fz-12;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  font-style: normal;
  font-weight: $medium;
  line-height: 14px;
  margin-bottom: 4px;
}
.selectArow{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer; 
}
