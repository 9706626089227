@import '../../styles/var';
@import '../../styles/mixins';

.borderCont{
  position: relative;
  height: 89px;
  width: 144px;
  margin-bottom: 10px;
  margin-top: 10px;
  gap:12px;
  .img{
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @include size(80px, 80px);
    object-fit: cover;
  }
  // .borderItemTopLeft, .borderItemTopRight, .borderItemBottomLeft, .borderItemBottomRight{
  //   width: 40px;
  //   height: 29px;
  // }
  // .borderItemTopLeft{
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   border-top: 2px solid $primary-color;
  //   border-left: 2px solid $primary-color;
  //   border-radius: 7px 0 0 0px;
  // }
  // .borderItemTopRight{
  //   position: absolute;
  //   top: 0;
  //   right: 0;
  //   border-top: 2px solid $primary-color;
  //   border-right: 2px solid $primary-color;
  //   border-radius: 0 7px 0 0px;
  // }
  // .borderItemBottomLeft{
  //   position: absolute;
  //   bottom: 0;
  //   left: 0;
  //   border-bottom: 2px solid $primary-color;
  //   border-left: 2px solid $primary-color;
  //   border-radius: 0 0 0 7px;
  // }
  // .borderItemBottomRight{
  //   position: absolute;
  //   bottom: 0;
  //   right: 0;
  //   border-bottom: 2px solid $primary-color;
  //   border-right: 2px solid $primary-color;
  //   border-radius: 0 0 7px 0;
  // }
  .close{
    position: absolute;
    top: 0;
    right: -15px;
    font-size: $fz-16;
    font-weight: 600;
    color: $error-color;
    cursor: pointer;
  }

// .borderContTransform{
//   width: 89px;
//   height: 144px;

  .img{
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    @include size(80px, 80px);
    object-fit: contain;
  }
}