@import '../../styles/var';
@import '../../styles/mixins';

.cont{
  // margin-bottom: 8px;
  position: relative;

  svg{
    fill: $gray-color;
    margin-right: 3px;
   
  }
  // @media (max-width: 767px) {
  //   width: 100%;
  // }

  .hideText{
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $gray-color;
    position: absolute;
    right: 20px;
    top: 4.5rem;
  }
  .title{
    color: #11133E;
    font-family: Mardoto-Regular;
    font-size: $fz-16;
    font-style: normal;
    line-height: 30px;
  }

}
.input{
  @include size(100%, 46px);
  max-width: 501px;
  border-radius: 12px;
  border: 2px solid #97BCC7;
  padding: 0px 8px;
  background: white;
  font-size: $fz-12;
  font-family: Mardoto-Regular;
  // font-weight: 400
}