@import "../../../styles/var";
@import "../../../styles/mixins";
.backgraund {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000080;
  z-index: 1000;
}
.cont {
  display: flex;
  // width: 620px;
  min-width: 500px;
  width: 30%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 222;
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  @media (max-width: 1200px) {
    left: 40%;
  }
  @media (max-width: 800px) {
    left: 1%;
  }
  @media (max-width: 500px) {
    min-width: 400px;
  }
}

.profilePhotos {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  .myProfile {
    // color: #FEFEFE;
    font-family: Rubik-Medium;
    font-size: $fz-42;
    font-style: normal;
    font-weight: $medium;
    line-height: normal;
  }
  .profilePhoto {
    @include size(100px);
    @include imageSize(cover, center);
    border-radius: 50%;
  }
  .name {
    display: flex;
    align-items: end;
    margin-left: 50px;
  }
}
.formsCont {
  // margin-top: 54px;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.inputs {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  // justify-content: space-between;
  gap: 0px 10px;
  margin-top: 10px;
}

// .label{
//   width:46%;
//   // @media screen and (max-width: 480px){
//   //   width: 100%;
//   // }
// }

.imgContainer {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  // margin-bottom: 22px;
  background: $primary-color;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.changePhotoCont {
  cursor: pointer;
  width: 39px;
  height: 39px;
  border-radius: 100%;
  background: $white;
  position: absolute;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  right: 0;
}

.changePhoto {
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background-color: $white;
}
// .withSelected{
//   width: 46%;
//   margin-bottom: 24px;
//   height: 0;
//   @media (max-width: 654px) {
//     height: fit-content;
//   }
//   @media (max-width: 425px) {
//     width: 100%;
//   }
//   @media (max-width: 480px) {
//     width: 100%;
//   }
//   label{
//     width: 100% !important;
//     margin-bottom: 5px;
//   }
//   .selected{
//     display: flex;
//     align-items: center;
//     margin-bottom: 5px;
//     max-width: 501px;
//     &:first-child{
//       margin-top: -16px;
//     }
//     .title{
//       color:  $gray-color;
//       font-family: Mardoto-Regular;
//       font-size: $fz-14;
//       font-weight: $regular;
//       margin-right: 8px;
//       display: -webkit-box;
//       -webkit-line-clamp: 2;
//       -webkit-box-orient: vertical;
//       overflow: hidden;
//     }
//     svg{
//       cursor: pointer;
//     }
//   }
//   .selectedAddress{
//     margin-top: 10px;
//   }
// }
.withSelected {
  display: flex;
  width: 100%;
  flex-direction: column;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 30px;
  box-shadow: 0px 0px 0px 0px #0000000a;
  box-shadow: 0px 1px 2px 0px #0000000a;
  box-shadow: 0px 3px 3px 0px #00000008;
  box-shadow: 0px 7px 4px 0px #00000005;
}

.headerTitle {
  color: #11133e;
  font-family: Mardoto-Bold;
  font-size: $fz-18;
  font-style: normal;
  line-height: 30px;
}

.main {
  display: flex;
  width: 100%;
  padding: 24px 32px;
}

.avatarTitle {
  color: #11133e;
  font-family: Mardoto-Bold;
  font-size: $fz-16;
  font-style: normal;
  line-height: 30px;
}

.avatarIconDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
}
.buttonDiv {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
  margin-top: 16px;
  // @media (max-width: 480px) {
  //   justify-content: flex-start;
  //   }
}
.closeButton {
  cursor: pointer;
}
.inputeStyle {
  @include size(100%, 46px);
  max-width: 501px;
  border-radius: 8px;
  border: 1px solid #eaeef4;
  padding: 10px 20px;
  background: #f6fafd;
  font-size: $fz-12;
  font-family: Mardoto-Regular;
  font-weight: 400;
}
