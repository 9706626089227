@import '../../styles/var';
@import '../../styles/mixins';

.languageCont {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: space-between;
  // width: 280px;
  height: 48px;
  padding: 0 16px;
  background-color: $language-color;
  border-radius: 4px;
  cursor: pointer;
  position: relative;

  .languageText {
    font-family: Mardoto-Medium;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: $primary-color;
  }

  .languageContImg {
    width: 30px;
    height: 24px;
    border-radius: 4px;
    object-fit: cover;
    cursor: pointer;
  }

  .arrow {
    right: 27px;
    transition: transform 0.3s ease;
  }

  .arrowUp {
    transform: rotate(180deg);
  }

  .dropdownMenu {
    position: absolute;
    top: 110%;
    left: 0;
    width: 280px;
    background-color: #fff;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    z-index: 10;

    div {
      display: flex;
      align-items: center;
      padding: 10px;
      cursor: pointer;
      transition: background 0.2s;

      &:hover {
        background-color: #f0f0f0;
      }

      img {
        margin-right: 8px;
      }

      span {
        font-size: 14px;
        font-family: Mardoto-Regular;
        line-height: 24px;
        color: $primary-color;
      }
    }
  }
}