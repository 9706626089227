@import "../../../styles/var";
@import "../../../styles/mixins";
.backgraund {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000080;
  z-index: 1000;
}
.cont {
  display: flex;
  // width: 620px;
  min-width: 500px;
  width: 30%;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 222;
  background-color: rgb(255, 255, 255);
  border-radius: 12px;
  @media (max-width: 1200px) {
    left: 40%;
  }
  @media (max-width: 800px) {
    left: 1%;
  }
  @media (max-width: 500px) {
    min-width: 400px;
  }
}
.contNone {
  // margin-top: 54px;
  display: flex;
  flex-direction: column;
  width: 100%;
  // margin-top: 54px;
  min-width: 500px;
  width: 30%;
  position: absolute;
  left: 40%;
  top: 170px;
  z-index: 222;
  background-color: rgb(155, 155, 155);
  border-radius: 12px;

  @media (max-width: 1200px) {
    left: 40%;
  }

  @media (max-width: 800px) {
    left: 1%;
  }

  @media (max-width: 500px) {
    min-width: 400px;
  }
}
.formsCont {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.profilePhotos {
  .myProfile {
    font-family: Rubik-Medium;
    font-size: $fz-42;
    font-style: normal;
    font-weight: $medium;
    line-height: normal;
  }
  .profilePhoto {
    @include size(120px);
    @include imageSize(cover, center);
    border-radius: 50%;
  }
  .name {
    display: flex;
    align-items: end;
    margin-left: 50px;
  }
}
.inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 0px 10px;
  margin-top: 10px;
}

label {
  width: 45%;
  // @media (max-width: 425px) {
  //   width: 100%;
  // }
}
.selected__imgcont {
  margin-right: 8px;
  @include size(30px);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: $primary-color;
}
.imageDefault {
  object-fit: contain;
  @include size(20px);
}
.withSelected {
  width: 46%;
  margin-bottom: 19px;
  height: 0;
  @media (max-width: 654px) {
    height: fit-content;
  }
  @media (max-width: 425px) {
    width: 100%;
  }
  label {
    width: 100% !important;
  }
  .selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
    max-width: 501px;
    &:first-child {
      margin-top: -16px;
    }
    .title {
      color: #62708d;
      font-family: Rubik-Regular;
      font-size: $fz-14;
      line-height: 20px;
      margin-right: 8px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .image {
      margin-right: 8px;
      @include size(30px);
      border-radius: 50%;
      object-fit: cover;
    }
    svg {
      cursor: pointer;
    }
  }
  .selectedAddress {
    margin-top: 10px;
  }
}
.selectedItem {
  display: flex;
  align-items: center;
  gap: 8px;
}
.selectedItemIconDiv {
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}
.selectedItemIcon {
  width: 17px;
  height: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
}
.changePhotoCont {
  cursor: pointer;
  width: 39px;
  height: 39px;
  border-radius: 100%;
  background: $white;
  position: absolute;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0px;
  right: 0;
}

.imgContainer {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: $primary-color;
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 30px;
  box-shadow: 0px 0px 0px 0px #0000000a;
  box-shadow: 0px 1px 2px 0px #0000000a;
  box-shadow: 0px 3px 3px 0px #00000008;
  box-shadow: 0px 7px 4px 0px #00000005;
}

.headerTitle {
  color: #11133e;
  font-family: Mardoto-Bold;
  font-size: $fz-18;
  font-style: normal;
  line-height: 30px;
}

.main {
  display: flex;
  width: 100%;
  padding: 24px 32px;
}
.buttonDiv {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
  margin-top: 16px;
  // @media (max-width: 480px) {
  //   justify-content: flex-start;
  //   }
}
.closeButton {
  cursor: pointer;
}
.avatarTitle {
  color: #11133e;
  font-family: Mardoto-Bold;
  font-size: $fz-16;
  font-style: normal;
  line-height: 30px;
}

.avatarIconDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  height: 100px;
}
.menagerList {
  width: 100%;
  display: flex;
  height: 40px;
  align-items: center;
  justify-content: space-between;
}
.menagerIconDiv {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  cursor: pointer;
  border-radius: 50%;
  margin-right: -10px;
  border: 2px solid rgb(255, 255, 255);
}

.menagerIcon {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  cursor: pointer;
  border-radius: 50%;
}
.addresses {
  width: 100%;
  height: fit-content !important;
}
.menagerListImagesDiv {
  display: flex;
}
.addMenagerDiv {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  cursor: pointer;
  border-radius: 50%;
}

.addMenagerIcon {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  cursor: pointer;
  border-radius: 50%;
}
.menagerText {
  color: #11133e;
  font-family: Mardoto-Medium;
  font-size: $fz-16;
  font-style: normal;
  line-height: 30px;
}
.menagerListPopUp {
  display: flex;
  flex-direction: column;
  width: 408px;
  background-color: white;
  position: absolute;
  top: 100px;
  left: 45px;
  z-index: 1222;
  border-radius: 12px;
  box-shadow: 0px 0px 0px 0px #0000000a;

  box-shadow: 0px 1px 2px 0px #0000000a;

  box-shadow: 0px 3px 3px 0px #00000008;

  box-shadow: 0px 7px 4px 0px #00000005;
}

.menagerListPopUpMain {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.menagerListItem {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 28px;
  gap: 16px;
}

.menagerListItemText {
  color: #070018;
  font-family: Mardoto-Bold;
  font-size: $fz-13;
  font-style: normal;
  line-height: 23px;
}

.menagerImageDiv {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  cursor: pointer;
  border-radius: 50%;
  border: 2px solid rgb(255, 255, 255);
}

.menagerImage {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: contain;
  cursor: pointer;
  border-radius: 50%;
}
.buttonCont {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
  padding: 16px 32px;
}
.inputeStyle {
  @include size(100%, 46px);
  max-width: 501px;
  border-radius: 8px;
  border: 1px solid #eaeef4;
  padding: 10px 20px;
  background: #f6fafd;
  font-size: $fz-12;
  font-family: Rubik-Regular;
  font-weight: 400;
}
