@import "../../styles/var";
@import "../../styles/mixins";

.tarifItem {
  cursor: pointer;
  border-radius: 6px;
  border: 1px solid #f2f2f2;
  text-align: center;
  padding: 44px 30px;
  background-color: #f7f8f9;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  min-height: 450px;
  height: 100%;
  position: relative;
  // justify-content: space-between;
  color: $white;
  margin: 30px 0px;
  &:first-child{
    margin-right: 25px;
  }

  @media screen and (max-width: 480px) {
    min-height: 162px;
    height: 100%;
    padding: 32px 29px;
    margin: 10px;
    &:first-child{
      margin-right: 10px;
    }
  }

  .typesCont {
    margin: 15px 0px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    .typesItem {
      text-align: start;
      // margin: 26px 0px;
      margin-bottom: 10px;
      font-size: $fz-18;
      font-family: Mardoto-Semibold;
      font-weight: $semiBold;
      svg{
        margin-right: 16px;
      }
      @media screen and (max-width: 480px) {
        font-size: 13px;
      }
    }
  }

  .title {
    font-family: Mardoto-SemiBold;
    font-size: $fz-23;
    font-style: normal;
    line-height: 30px;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-weight: $semiBold;
    text-align: start
  }

  button {
    @include size(100px, 38px);
    background: #44C7E3
  }
}

.premium {
  background: #FF4572;
}
.basic {
  background: #44C7E3;
}

.acivebasic {
  border: 1px solid #44C7E3;
}
.priceMonth{
  display: flex;
  align-items: baseline;
  .price{
    font-family: Mardoto-Bold;
    font-weight: $bold;
    font-size: $fz-51;
    margin-right: 7px;
  }
  .month{
    font-family: Mardoto-SemiBold;
    font-size: $fz-15;
    font-weight: $semiBold;
    text-align: left;
  }
}
.acivepremium {
  border: 1px solid #FF4572;
}