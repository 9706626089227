@import './styles/var';
@import './styles/mixins';


.cont {
  // min-height: 97vh;
  height: 100vh;

  @media screen and (max-width: 480px) {
    align-items: flex-start !important;
  }

  .languageCont {
    max-width: 1150px;
    width: 100%;
    position: relative;

  }

  .title {
    color: $gray-color;
    text-align: center;
    font-size: $fz-24;
    font-style: normal;
    font-family: Mardoto-Medium;
    line-height: 22px;
    letter-spacing: 0.12px;
    margin-bottom: 40px;

    @media (max-width: 425px) {
      font-size: $fz-16;
    }
  }

  .subHeading {
    display: flex;
    gap: 10px;
    font-family: Mardoto-Regular;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    margin-bottom: 24px;

    :first-child {
      font-family: Mardoto-Regular;
      font-size: 14px;
      font-weight: 400;
      line-height: 16.41px;
      color: $black-2;
    }

    :last-child {
      font-family: Mardoto-Bold;
      font-size: 14px;
      font-weight: 700;
      line-height: 16.41px;
      color: $primary-profession-color;
    }
  }

  .tubButtons {
    border-radius: 4px;
    background: #F2F1EF;
    color: $primary-color;
    margin-bottom: 18px;
    @include size(500px, 34px);
    max-width: 100%;

    @media (max-width: 425px) {
      width: 100%;
    }

    .buttons {
      width: 50%;
      cursor: pointer;
      

      @media (max-width: 425px) {
        width: 50%;
      }
    }

    .buttons.active {
      border-radius: 4px;
      background: $primarySign-up;
      color: white;
    }
  }

  .regisButton {
    width: 100%;
    font-size: 14px;

    button {
      width: 100%;
    }

    .bottomText {
      width: 100%;
      margin: auto;
      text-align: center;
    }
  }

  .forgetPassword {
    margin-top: 8px;
    width: 100%;
    display: flex;
    justify-content: end;
    text-decoration: none;
    font-size: $fz-14;
    font-family: Mardoto-Medium;

    @media screen and (max-width: 320px) {
      margin-top: 10px;
    }
  }

  .linkTitle {
    color: #556FF6;
    margin-top: 8px;
  }

  .description {
    color: $gray-color;
    font-size: $fz-18;
    font-family: Mardoto-Medium;
    line-height: 22px;
    letter-spacing: 0.12px;
  }

  .formCont {
    padding: 40px;
    border-radius: 24px;
    background: #FEFEFE;
    box-shadow: 0px 14px 42px 0px rgba(20, 20, 43, 0.14);
    width: 100%;
    max-width: 500px;

    @media (max-width: 1440px) {
      margin: 40px 0px;
    }

    .inLine {
      gap: 20px;
      input {
        width: 100%;


        @media (max-width: 767px) {
          width: 100%;
        }
      }
    }

    .heading {
      color: $primary-color;
      font-size: $fz-30;
      font-family: Mardoto-Bold !important;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.8px;
      margin-bottom: 8px;


      @media (max-width: 480px) {
        font-size: $fz-24;
      }

      @media (max-width: 380px) {
        font-size: $fz-18;
      }
    }

    .subTitle {
      font-family: Mardoto-Medium;
      font-size: 16px;
      font-weight: 500;
      line-height: 30px;
      color: $primary-color;
    }

    button {
      margin-top: 44px;
      margin-bottom: 16px;
      font-size: $fz-14;
    }

    .bottomText {
      color: $gray-color;
      font-weight: $regular;
      font-size: $fz-16;
      font-style: normal;
      line-height: normal;

      .signUp {
        color: $primarySign-up;
        cursor: pointer;
        text-decoration: none;
        margin-left: 4px;
      }
    }

    @media (max-width: 425px) {
      padding: 0 24px;
      box-shadow: none;
      width: 100%;
      margin-top: 40px;
    }
  }

  .inputCont {
    margin-bottom: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  .inputBottonText {
    font-family: Inter;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.52px;
    color: #898989;
    margin-bottom: 8px;
  }

  .customInput {
    width: 100%;
    border-radius: 8px;
    border-width: 1px;
    color: $primary-color;

    & input {
      background-color: #F6FAFD;
    }

  }

  .buttonStyle {
    border-radius: 8px;
    width: 100%;
    background: #556FF6;
    gap: 16px;
    padding-top: 10px;
    padding-right: 32px;
    padding-bottom: 10px;
    padding-left: 32px;
    color: #F6FAFD;
    border: 1px solid #EAEEF4
  }

  .LoginSvg {
    @include size(537px, 543px);
    margin-right: 110px;

    @media (max-width: 1300px) {
      @include size(337px, 343px);
      margin-right: 50px;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .RegisSvg {
    @include size(652px, 550px);
    margin-right: 47px;

    @media (max-width: 1300px) {
      @include size(337px, 343px);
      margin-right: 50px;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }
}

.callButton {
  @include size(239px, 48px);
  border-radius: 5px;
  background: $button-color;
  color: white;
  border: none;
  padding: 8px 12px;
  font-size: $fz-14;
  display: flex;
  justify-content: center;
  align-items: center;

  .phone {
    margin-left: 10px;
  }

  svg {
    path {
      fill: $white;
    }
  }

}

.textActivateProfile {
  font-size: $fz-16;
}

.logout {
  color: $button-color;
  font-size: $fz-14;
  margin-top: 15px;
  cursor: pointer;
}

.congratulations {
  .text {
    color: $button-color;
    text-align: center;
    font-family: Mardoto-SemiBold;
    font-size: $fz-24;
    font-style: normal;
    line-height: 22px;
    /* 91.667% */
    letter-spacing: 0.12px;
  }

  .desciption {
    font-size: $fz-16;
    color: $gray-color;
    text-align: center;
    font-style: normal;
    font-family: Mardoto-Medium;
    line-height: 22px;
    /* 137.5% */
    letter-spacing: 0.08px;
    margin-top: 16px;

  }

  button {
    margin-top: 24px !important;
  }
}