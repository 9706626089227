@import '../../styles/var';
@import '../../styles/mixins';
.cont{
  width: 49%;
  // background-color: red;
  margin-bottom: 24px;
  position: relative;
  svg{
    fill: $gray-color;
    margin-right: 3px;
  }
  // @media (max-width: 767px) {
  //   width: 100%;
  // }


}
  .title{
    color: #11133E;
    font-family: Mardoto-Regular;
    font-size: $fz-16;
    font-style: normal;
    line-height: 30px;
  }
.selectCont{
  width: 100%;
  display: flex;
  align-items: center;
  background: #F6FAFD;
  border-radius: 8px;
  border: 1px solid #EAEEF4;
  // border: 1px solid #EAEEF4;
  // border: 2px solid #97BCC7;
  // @include size(100%, 46px);
  //   max-width: 501px;
  input{
    display: flex;
    width: 100%;
    height: 46px;
    border: none;
    outline: none !important;
    padding-left: 5px;
    border-radius: 12px;
    background: #F6FAFD;
  }
  .line{
    width: 2px;
    height: 30px;
    // background: #97BCC7;
  }
}
.bankImgNotAvailable{
  @include size(36px, 24px);
  margin: 0px 16px;

}
.bankImg{
  @include size(36px, 24px);
  margin: 0px 16px;
}
.bankScoreInput{
  font-size: $fz-12;
  font-family: Mardoto-Regular;
}
.bankScoreInputNotAvailable{
  margin-left: 15px;
  color: rgba(25, 39, 57, 0.94);
  font-family: Mardoto-Regular;
  font-size: $fz-16;
  font-style: normal;
  font-weight: $regular;
  // font-size: $fz-16;
}