@import '../../../styles/var';
@import '../../../styles/mixins';
.main{
  display: flex;
  width: 75%;
  border-radius: 10px;
  background-color: white;
}
.resetPassword{
  button{
    width: 100%;
  }
}
.userProfileButton{
  @media (max-width: 425px) {
    width: 100%;
  }
}
.user_name{
  margin-top: 20px;
  font-family: Rubik-Medium;
  font-weight: $medium;
  font-size: $fz-18;
}
.profilePhotos{
  padding: 76px 0 24px 24px;
  border-radius: 10px;
  .myProfile{
    // color: #FEFEFE;
    font-family: Rubik-Medium;
    font-size: $fz-42;
    font-style: normal;
    font-weight: $medium;
    line-height: normal;
  }
  .profilePhoto{
    @include size(120px);
    @include imageSize(cover, center);
    border-radius: 50%;
  }
  // .nameImages{
  //   position: absolute;
  //   bottom: -110px;
  //   padding-right: 80px;
  // }
  .name{
    display: flex;
    align-items: end;
    margin-left: 50px;
  }

}
.formsCont{
  // margin-top: 54px;
  margin-bottom: 40px;
  .inputs{
    gap: 0px 50px;
    margin-top: 10px;
    label{
      width:46%;
      @media (max-width: 425px) {
        width: 100%;
      }
    }
  }
  @media screen and (max-width: 480px){
    margin-top: 30px;
  }
}

.imgContainer{
  @include size(120px);
  border-radius: 50%;
  background-color: $primary-color;
  display: flex;
  justify-content: center;
  align-items: center;
  img{
    @include size(70px)
  }
}
.inputeStyle {
  @include size(100%, 46px);
  max-width: 501px;
  border-radius: 8px;
  border: 1px solid #eaeef4;
  padding: 10px 20px;
  background: #f6fafd;
  font-size: $fz-12;
  font-family: Rubik-Regular;
  font-weight: 400;
  margin-top: 12px;
  margin-bottom: 24px;
}
.header{
  width: 100%;
  border-bottom: 1px solid rgb(212, 212, 212);
  padding: 24px;
}
.mainItem{
  padding: 24px;
}
.headerTitle{
font-weight: 700;
font-size: 28px;
line-height: 40px;
letter-spacing: 0%;

}