@import "../../../styles/var";
@import "../../../styles/mixins";
.cont {
  display: flex;
  flex-direction: column;
  width: 100%;
  // margin-top: 54px;
  min-width: 900px;
  width: 30%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 222;
  background-color: white;
  border-radius: 12px;
  padding-bottom: 24px;
  background-color: #fff;
  // height: 80vh;
}

.pageHeading {
  color: $black;
  font-family: Rubik-Medium;
  font-size: $fz-42;
  font-style: normal;
  font-weight: $medium;
  line-height: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media (max-width: 1510px) {
    font-size: $fz-28;
  }

  @media (max-width: 1024px) {
    font-size: $fz-24;
  }
}

.sectionHeading {
  color: #11133e;
  font-family: Mardoto-Regular;
  font-size: $fz-16;
  font-style: normal;
  line-height: 30px;

  /* 110% */
  // letter-spacing: 0.1px;
  // margin-bottom: 15px;
  // margin-top: 15px;
  // margin-top: 30px;
  // @media (max-width: 425px) {
  //   font-size: $fz-14;
  // }
}
.cardContVacation {
  margin: 0 !important;
}
.cardContHeading {
  margin-top: 0;

  @media (max-width: 425px) {
    font-size: $fz-14;
  }
}

.cardUpload {
  .cardUploadItem {
    svg {
      margin-bottom: 10px;
      cursor: pointer;
    }

    .cardTitle {
      color: $gray-color;
      // text-align: center;
      font-family: Mardoto-Regular;
      font-size: $fz-12;
      font-style: normal;
      font-weight: $regular;
      line-height: normal;
    }
  }

  .cardLine {
    gap: 30px;
    margin-bottom: 30px;

    @media (max-width: 425px) {
      margin-bottom: 24px;
    }
  }
}

.headingButton {
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  padding: 5px 0px;
  width: 100%;
  background-color: $white;
  margin-bottom: 53px;
  z-index: 1;

  // margin-top: 44px;
  @media (max-width: 425px) {
    margin-bottom: 24px;
  }
}

.inputCont {
  gap: 0px 60px;

  label {
    height: fit-content !important;
    width: 45%;

    @media (max-width: 1150px) {
      width: 100%;
    }
  }
}

.documentLabel {
  margin-top: 15px;
  margin-bottom: 24px;

  svg {
    cursor: pointer;
  }
}
.label {
  color: #62708d;
  text-align: center;
  font-family: Mardoto-Regular;
  font-size: $fz-16;
  line-height: 30px;
}

.addProduct {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}
.inputDiv {
  display: flex;
  width: 100%;
}
.addProductItem {
  height: 46px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 20px;
  border: 1px solid #eaeef4;
  background: #f6fafd;
  border-radius: 73px;
  gap: 12px;
  cursor: pointer;
}
.addIconDiv {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  object-fit: cover;
  cursor: pointer;
}
.addIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  object-fit: cover;
  cursor: pointer;
}
.addProductText {
  font-family: Mardoto-Regular;
  font-size: 16px;
  color: #62708d;
  line-height: 30;
}
.inputeStyle {
  @include size(100%, 46px);
  // max-width: 501px;
  border-radius: 8px;
  border: 1px solid #eaeef4;
  padding: 10px 20px;
  background: #f6fafd;
  font-size: $fz-12;
  font-family: Mardoto-Regular;
  // font-weight: 400;

 
}
.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 30px;
  box-shadow: 0px 0px 0px 0px #0000000a;
  box-shadow: 0px 1px 2px 0px #0000000a;
  box-shadow: 0px 3px 3px 0px #00000008;
  box-shadow: 0px 7px 4px 0px #00000005;
}

.headerTitle {
  color: #11133e;
  font-family: Mardoto-Bold;
  font-size: $fz-18;
  font-style: normal;
  line-height: 30px;
}

.closeButton {
  cursor: pointer;
}
.buttonDiv {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  width: 100%;
  margin-top: 25px;
}
.main {
  display: flex;
  width: 100%;
  padding: 24px 32px;
  max-height: 650px;
  overflow: auto;
}
.defautDoc {
  width: 270px;
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-radius: 73px;
  cursor: pointer;
  background: #f6fafd;
  gap: 12px;
  border: 1px solid #eaeef4;
  // width: 250px;
  // height: 50px;
}
.iconDoc {
  display: flex;
  align-items: center;
  justify-content: center;
  object-fit: cover;
  width: 20px;
  height: 20px;
}
.titleStyle {
  color: #11133e;
  font-family: Mardoto-Medium;
  font-size: $fz-16;
  line-height: 30px;
}
.invoiceCont {
  width: 100%;
  position: absolute;
}
.taskNameDiv {
  display: flex;
  width: 100%;
}
.wrapDiv {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  // background-color: red;
}
.wrapDiv div {
  width: 400px;
}
// .wrapItem{
//   width: 200px;
//   background-color: blue;
// }
.picerDiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
.buttonUploadCont {
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 10px;
}
