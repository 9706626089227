@import '../styles/var';
@import '../styles/mixins';

.dialogCont{
    font-family: Mardoto-Medium;
    padding: 20px;
    border-radius: 24px !important;
    background: #FEFEFE;
    box-shadow: 0px 14px 42px 0px rgba(20, 20, 43, 0.14);
    width: 100%;
    max-width: 500px;
    min-width: 400px;
    .heading{
        font-size: $fz-16;
        font-style: normal;
        font-weight: $regular;
        color: #62708D;
        line-height: 30px;
        font-family: Mardoto-Bold !important;
    }
}
.header{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
}
.deleteTitle{
    color: #070018;
    font-size: $fz-16;
    font-style: normal;
    font-weight: $medium;
    line-height: 30px;
    font-family: Mardoto-Bold !important;
}
.infoDiv{
    display: flex;
    align-items: center;
    background: #EAEAEE;
    border-radius: 16px;
    padding: 10px 24px;
    gap: 10px;
}
.iconDiv{
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    width: 44px;
    height: 44px;
    border-radius: 50%;
}
.iconStyle{
    display: flex;
    align-items: center;
    justify-content: center;
    object-fit: cover;
    width: 44px;
    height: 44px;
    border-radius: 50%;
}
.spanDiv{
    display: flex;
    flex-direction: column;
}
.text{
    color: #11133E;
    font-size: $fz-16;
    font-style: normal;
    font-weight: $medium;
    line-height: 22px;
    font-family: Mardoto-Bold !important;
}
.imgContainer {
    width: 57px;
    height: 57px;
    border-radius: 8px;
    background: #556FF6;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 2px solid $primary-color;
  }