@import '../../styles/var';
@import '../../styles/mixins';

.button{
  border-radius: 70px;
  background: #556FF6;;
  color: white;
  border: none;
  padding: 10px 24px;
  white-space: nowrap;
  height: fit-content;
  gap: 8px;
  @media (max-width: 425px) {
    width: 100%;
  }
  .title{
    font-family: Mardoto-Medium;
    font-weight: $medium;
    // margin-left: 8px;
    font-size: $fz-14;
    line-height: 30px;
    letter-spacing: 0.07px;
  }
  svg{
    path{
      fill: $white;
    }
  }
}